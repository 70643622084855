<template>
    <div>
      <el-form ref="formRef" :model="rulesForm" label-width="94px" label-position="right" :rules="rules" class="">
        <el-form-item label="考试类型" prop="examType">
          <el-select v-model="rulesForm.examType" placeholder="考试类型" @change="typeChange" class="input-width-medium">
            <el-option
              v-for="item in rulesFormData.ExamType"
              :value="item.id"
              :key="item.id"
              :label="item.name"
            >{{ item.name }}
            </el-option>
          </el-select>
          <div class="tips">
              无考试类型可选？请点击
              <span
                  class="text"
                  @click="
                      $router.push({
                          name: 'SystemDict',
                          params:{
                            type:'examType'
                          }
                      })
                  "
                  >类型设置</span
              >
              进行添加
          </div>
        </el-form-item>
      </el-form>
      <div style="text-align: right;width:366px;">
        <xk-button type="info" @click="close">取消</xk-button>
        <xk-button type="primary" @click="save">确定</xk-button>
      </div>
    </div>
  </template>
  
  <script>
  
  export default {
    props: {
      rulesForm: {
        type: Object
      },
      rulesFormData: {
        type: Object
      },
      rules: {
        type: Object
      },
    },
  
    data() {
      return {
        showMode: false,
        dialogVisible: false
      }
    },
    methods: {
      /**
       * @Description:  选择考试类型并匹配id
       * @DoWhat: 
       * @UseScenarios: 
       * @Attention: 
       * @Author: 韩俊梅
       * @Date: 2024-08-02 18:02:14
       * @param { Object } data 当前选中的数据
       */      
      typeChange(data) {
        this.rulesForm.examType = this.rulesFormData.ExamType.find(item => item.id === data).id
      },
      /**
       * @Description: 关闭弹窗
       * @DoWhat: 
       * @UseScenarios: 
       * @Attention: 
       * @Author: 韩俊梅
       * @Date: 2024-08-02 18:04:15
       */      
      close() {
        this.$emit('close')
      },
      /**
       * @Description:  执行保存方法
       * @DoWhat: 
       * @UseScenarios: 
       * @Attention: 
       * @Author: 韩俊梅
       * @Date: 2024-08-02 18:04:33
       */      
      save() {
        this.$refs.formRef.validate((val) => {
          if (val) {
            this.$emit('save')
          }
        })
      },
    }
  }
  </script>
  
  <style scoped>
  .title {
    text-align: center;
    margin: 0;
  }
  .required {
    color: #f56c6c;
  }
  .content {
    flex: auto;
    height: 32px;
    line-height: 32px;
    border: 1px solid #ebeef5;
  }
  .tips {
      margin-top: 3px;
      line-height: 15px;
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      white-space: nowrap;
  
      .text {
          color: #4060FF;
          cursor: pointer;
      }
  }
  </style>
  