<template>
  <div>
    <el-form inline ref="formRef" :model="rulesForm" :rules="rules" label-width="130px">
        <el-form-item label="成绩转换为等级" style="width: 100%" prop="isChange">
          <el-radio v-model="rulesForm.isChange" label="1">是</el-radio>
          <el-radio v-model="rulesForm.isChange" label="0">否</el-radio>
        </el-form-item>
        <el-form-item label="转换方式" style="width: 100%" prop="changeType">
          <el-radio v-model="rulesForm.changeType" label="1" @change="radioChange">按成绩</el-radio>
          <el-radio v-model="rulesForm.changeType" label="0" @change="radioChange">按比例</el-radio>
        </el-form-item>
        <template v-if="rulesForm.changeType === '1'">
          <div v-for="(item,index) in resultList" :key="index">
            <el-form-item label="等级" required>
              <el-input v-model="item.grade" style="width: 60px"></el-input>
            </el-form-item>
            <el-form-item label="设置分数区间" required>
              <el-input type="number" v-model="item.section1" style="width: 80px"></el-input>
              <span>  -  </span>
              <el-input type="number" v-model="item.section2" style="width: 80px;margin-right: 10px"></el-input>
              <el-button v-if="index === 0" @click="add(resultList, {grade: '', section1: '', section2: ''})">添加
              </el-button>
              <el-button v-else @click="del(resultList,index)" >删除
              </el-button>
            </el-form-item>
          </div>
        </template>
        <template v-else>
          <div v-for="(item,index) in ratioList" :key="index">
            <el-form-item label="等级" required>
              <el-input v-model="item.grade" style="width: 60px"></el-input>
            </el-form-item>
            <el-form-item label="该等级人数占比" required>
              <el-input type="number" v-model="item.num" style="width: 174px;margin-right: 10px"></el-input>
              <el-button v-if="index === 0" @click="add(ratioList,{grade: '', num: ''})" >添加
              </el-button>
              <el-button v-else @click="del(ratioList,index)" >删除
              </el-button>
            </el-form-item>
          </div>
        </template>
        <div style="text-align: right">
          <el-button @click="close">取消</el-button>
          <el-button @click="save">
            下一步
          </el-button>
        </div>
      </el-form>
  </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
  props: {
    rulesForm: {
      type: Object
    },
    rules: {
      type: Object
    },
  },
  computed: {
    ...mapState({
      templateInfo: state => state.templateInfo,
    })
  },
  data() {
    return {
      resultList: [{grade: '', section1: '', section2: ''}],
      ratioList: [{grade: '', num: ''}],
    }
  },
  methods: {
    save() {
      let flag = ''
      if (this.rulesForm.changeType === '1') {
        this.resultList.forEach((item) => {
          if (item.grade === '' || item.section1 === '' || item.section2 === '') {
            flag = 1
            return this.$message.warning('不能为空')
          } else {
            this.rulesForm.grade.push(item.grade)
            this.rulesForm.num.push(`${item.section1},${item.section2}`)
          }
        })
      } else {
        this.ratioList.forEach((item) => {
          if (item.grade === '' || item.num === '') {
            flag = 1
            return this.$message.warning('不能为空')
          } else {
            this.rulesForm.grade.push(item.grade)
            this.rulesForm.num.push(item.num)
          }
        })
      }
      if (flag !== 1) {
        this.$refs.formRef.validate((val) => {
          if (val) {
            this.resultList = [{grade: '', section1: '', section2: ''}]
            this.ratioList = [{grade: '', num: ''}]
            this.$emit('save')
          }
        })
      }
    },
    radioChange() {
      this.resultList = [{grade: '', section1: '', section2: ''}]
      this.ratioList = [{grade: '', num: ''}]
      this.rulesForm.grade = []
      this.rulesForm.num = []
    },
    add(data, params) {
      data.push(params)
    },
    del(data, index) {
      data.splice(index, 1)
    },
    close() {
      this.radioChange()
      this.$emit('close')
    },
  }
}
</script>
