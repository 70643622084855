<template>
  <div>
    <el-form ref="formRef" :model="rulesForm" label-width="94px" label-position="right" :rules="rules" class="common-form">
      <el-form-item label="年级" prop="gradeId">
        <el-select v-model="rulesForm.gradeId" placeholder="请选择年级" @change="gradeChange" class="input-width-medium">
          <el-option
            v-for="item in rulesFormData.gradeList"
            :value="item.id"
            :key="item.id"
            :label="item.name"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="学年" prop="schoolYear">
        <el-select v-model="rulesForm.schoolYear" placeholder="请选择学年"  @change="schoolYearChange" class="input-width-medium">
          <el-option
            v-for="item in rulesFormData.schoolYear"
            :value="item.id"
            :key="item.id"
            :label="item.name"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="学期" prop="semester">
        <el-select v-model="rulesForm.semester" placeholder="请选择学期" @change="semesterChange" class="input-width-medium">
          <el-option label="上学期" value="1" />
          <el-option label="下学期" value="2" />
        </el-select>
      </el-form-item>
      <el-form-item label="考试类型" prop="examType">
        <el-select v-model="rulesForm.examType" placeholder="考试类型" @change="typeChange" class="input-width-medium">
          <el-option
            v-for="item in filterExamType(rulesFormData.ExamType)"
            :value="item.id"
            :key="item.id"
            :label="item.name"
          />
        </el-select>
        <div class="tips">
            无考试类型可选？请点击
            <span
                class="text"
                @click="
                    $router.push({
                        name: 'SystemDict',
                        params:{
                          type:'examType'
                        }
                    })
                "
                >类型设置</span
            >
            进行添加
        </div>
      </el-form-item>
      <el-form-item label="考试时间" prop="examTime">
        <el-date-picker
            :value-format="'yyyy-MM-dd'"
            v-model="rulesForm.examTime"
            style="width: 100%;"
            type="date"
            placeholder="选择日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item v-if="rulesForm.gradeStage == 1" label="考试模式" prop="examinationMode">
        <div style="float: left;margin-right: 20px">
          <el-button :type="rulesForm.examinationMode === '1' ? 'primary': ''" @click="modeClick('1')" style="width: 100px">普通</el-button>
          <div style="color: #C0C4CC">普通即为全科科目</div>
        </div>
        <div style="float: left">
          <el-button :type="rulesForm.examinationMode === '2' ? 'primary': ''" @click="modeClick('2')" style="width: 100px">高考</el-button>
          <div style="color: #C0C4CC">文科或理科模式、3+1+2或3+3模式</div>
        </div>
      </el-form-item>
      <el-form-item label="科目" prop="examSubjects">
        <div class="input-width-medium">
          <el-button
            v-for="item in rulesFormData.teachingSubjects"
            :type="item.sel ? 'primary' : ''"
            :disabled="rulesForm.examinationMode === '2'"
            :key="item.id"
            @click="subjectClick(item)"
            style="margin-bottom: 10px;margin-right: 10px;margin-left: 0"
          >
            {{ item.name }}
          </el-button>
        </div>
        </el-form-item>
      <el-form-item label="考试名称" prop="examName">
        <el-input v-model="rulesForm.examName" placeholder="请输入考试名称"  class="input-width-medium" maxlength="30" show-word-limit></el-input>
      </el-form-item>
      <el-form-item label="满分" prop="fullScore">
        <el-input v-model="rulesForm.fullScore"   placeholder="请输入该场考试的满分"  class="input-width-medium"
        onkeyup="value=value.replace(/[^\d]/g,'')"></el-input>
      </el-form-item>
    </el-form>
    <div style="text-align: right;width:546px;">
	  <xk-button type="info" @click="close">取消</xk-button>
      <xk-button type="primary" @click="save">确定</xk-button>
      <xk-button type="warning" @click="preview">预览模板</xk-button>

    </div>
    <el-dialog :visible.sync="dialogVisible" append-to-body modal-append-to-body>
      <div class="title content">{{ rulesForm.examName }}</div>
      <div class="required content">填写说明： *为必填项，学生未参加的科目考试，请标注“-1”。</div>
      <div style="display: flex;border-left: 1px solid #ebeef5">
        <div class="content"><span class="required">*</span>年级</div>
        <div class="content"><span class="required">*</span>班级</div>
        <div class="content"><span class="required">*</span>姓名</div>
        <div class="content"><span class="required">*</span>唯一号</div>
        <div class="content" v-for="(item,index) in rulesFormData.teachingSubjects.filter(i => i.sel === true)" :key="index">{{ item.name }}</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>

export default {
  props: {
    rulesForm: {
      type: Object
    },
    rulesFormData: {
      type: Object
    },
    rules: {
      type: Object
    },
    list: {
      type: Array
    }
  },

  data() {
    return {
      showMode: false,
      dialogVisible: false
    }
  },
  methods: {
    setExamName() {
      // 编辑考试，不自动修改考试名称
      if (this.rulesForm.id !== ''){
        return
      }
      this.rulesForm.examName = this.rulesForm.gradeName + this.rulesForm.yearName + this.rulesForm.semesterName + this.rulesForm.examTypeName
    },
    modeClick(data) {
      if (data === '2') {
        let baseSubjects = ['化学', '历史', '数学', '语文', '物理', '生物', '政治', '英语', '地理']
        this.rulesFormData.teachingSubjects.forEach((item) => {
          if (baseSubjects.includes(item.name)) {
            item.sel = true
          }
        })
      }
      this.rulesForm.examinationMode = data
    },
    gradeChange(data) {
      let find = this.rulesFormData.gradeList.find(item => item.id === data)
      this.showMode = find.greadStage == 1
      this.rulesForm.gradeName = find.name
      this.rulesForm.gradeStage = find.greadStage
      this.setExamName()
    },
    schoolYearChange(data) {
      this.rulesForm.yearName = this.rulesFormData.schoolYear.find(item => item.id === data).name
      this.setExamName()
    },
    semesterChange(data) {
      if (data === '1') {
        this.rulesForm.semesterName = '上学期'
      } else if (data === '2') {
        this.rulesForm.semesterName = '下学期'
      } else {
        this.rulesForm.semesterName = ''
      }
      this.setExamName()
    },
    typeChange(data) {
      this.rulesForm.examTypeName = this.rulesFormData.ExamType.find(item => item.id === data).name
      this.setExamName()
    },
    subjectClick(data) {
      data.sel = !data.sel
    },
    preview() {
      this.dialogVisible = true
    },
    close() {
      this.$emit('close')
    },
    filterExamType(data) {
        if (this.rulesForm.examTypeName === "云阅") {
            return data
        } else {
            return data.filter(e => e.name != "云阅")
        }
    },
    save() {
      this.rulesForm.examSubjects = this.rulesFormData.teachingSubjects.filter(i => i.sel === true).map(i => i.name).toString()
      this.rulesForm.subjectCode = this.rulesFormData.teachingSubjects.filter(i => i.sel === true).map(i => i.code).toString()
      this.$refs.formRef.validate((val) => {
        if (val) {
          this.$emit('save')
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
.title {
  text-align: center;
  margin: 0;
}
.required {
  color: #f56c6c;
}
.content {
  flex: auto;
  height: 32px;
  line-height: 32px;
  border: 1px solid #ebeef5;
}
.tips {
    margin-top: 3px;
    line-height: 15px;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    white-space: nowrap;

    .text {
        color: #4060FF;
        cursor: pointer;
    }
}
</style>
