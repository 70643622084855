<template>
    <div class="sync-exam">
        <el-form :model="listQuery" class="common-form" label-position="right"  label-width="94px">
            <el-form-item label="年级" >
                <el-select class="input-width-medium" v-model="listQuery.gradeName" multiple placeholder="年级">
                    <el-option
                        v-for="item in gradeList"
                        :key="item.id"
                        :label="item.gradeName"
                        :value="item.gradeName"
                    />
                </el-select>
            </el-form-item>

            <el-form-item label="考试名称">
                <el-input class="input-width-medium" v-model="listQuery.examName" placeholder="考试名称"></el-input>
            </el-form-item>

            <el-form-item label="考试时间" style="width:547px;">
                <el-date-picker style="width: 219px" v-model="listQuery.startTregionfront" type="date" placeholder="考试开始时间"></el-date-picker>
                <span> - </span>
                <el-date-picker style="width: 219px" v-model="listQuery.startTregionafter" type="date" placeholder="考试结束时间"></el-date-picker>
            </el-form-item>

            <el-form-item style="text-align:right;width:546px;">
                <el-button type="primary" @click="query">查询</el-button>
                <el-button type="enquiry" @click="reset">重置</el-button>
            </el-form-item>
        </el-form>

        <!-- <div>
            <el-button type="text" @click="toggleSelection('all')">全选</el-button>
            <el-button type="text" @click="toggleSelection('invert')">反选</el-button>
            <el-button style="color: #F56C6C" type="text" @click="toggleSelection('clear')">清除</el-button>
        </div> -->

        <el-table ref="table" :data="list" style="width: 100%" max-height="460" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="45"></el-table-column>

            <el-table-column prop="itemName" show-overflow-tooltip label="考试名称"></el-table-column>

            <el-table-column prop="gradeName" width="65" label="年级"></el-table-column>

            <el-table-column label="考试时间" show-overflow-tooltip>
                <template slot-scope="scope">{{ scope.row.startDate + '~' + scope.row.endDate }}</template>
            </el-table-column>
        </el-table>

        <div class="footer">
			<el-button type="enquiry" @click="cancel">取消</el-button>
            <el-button type="primary" @click="submit">确认同步</el-button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SyncExam',
    components: {},
    props: {
        gradeList: {
            type: Array
        },
        listQuery: {
            type: Object
        },
        list: {
            type: Array
        },
        selected: {
            type: Array
        }
    },
    data() {
        return {

        }
    },
    filters: {},
    computed: {},
    watch: {},
    beforeCreate() {},
    created() {
    },
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
    methods: {
        query() {
            this.$emit('query')
        },
        reset() {
            this.$emit('reset')
        },
        toggleSelection(mode) {
            if (mode == 'all') {
                this.$refs.table.clearSelection();
                this.$refs.table.toggleAllSelection()
            } else if (mode == 'invert') {
                this.list.forEach(row => {
                    this.$refs.table.toggleRowSelection(row);
                })
            }else if (mode == 'clear'){
                this.$refs.table.clearSelection();
            }
        },
        handleSelectionChange(selected) {
            console.log("[handleSelectionChange]", selected)
            this.$emit("selChange", selected)
        },
        submit() {
            this.$emit("submit")
        },
        cancel() {
            this.$emit("cancel")
        },
    }
}
</script>

<style lang="scss" scoped>
$base-width:546px;
.footer {
	width: $base-width;
    height: 66px;
    background: #FFFFFF;
    border-radius: 0px 0px 6px 6px;
    line-height: 66px;
    text-align: right;
}
</style>
